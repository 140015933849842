import { userService } from "@/app/api/user";
import { ManageLocationsResponse } from "@/types/manage-locations";
import type {
  AllLocationsResponse,
  CleanLocationDraftForServer,
  IndividualScreenerLocationStatusType,
  ScreenerLocation,
  ScreenerLocationsTableResponse,
} from "@/types/screener-location";
import type { Nullable } from "@/types/utils";

//////////////////////////////////////
//// Screener Location Table Data ////
//////////////////////////////////////

export const getTableScreenerLocationsByScreener = async (
  screenerId: string,
): Promise<AllLocationsResponse> => {
  try {
    const response =
      await userService.axiosWithUserAuth.get<AllLocationsResponse>(
        `/screener-location/cx/${screenerId}/`,
      );
    return response.data;
  } catch (error) {
    console.error("Error fetching screener location data:", error);
    throw error;
  }
};

export const getAssignedScreenerLocations =
  async (): Promise<ScreenerLocationsTableResponse> => {
    try {
      const response =
        await userService.axiosWithUserAuth.get<ScreenerLocationsTableResponse>(
          "/screener-location/assigned",
        );
      return response.data;
    } catch (error) {
      console.error("Error fetching assigned screener locations:", error);
      throw error;
    }
  };

export const assignUserToLocation = async (
  locationId: string,
  userId: Nullable<string>,
) => {
  try {
    const response = await userService.axiosWithUserAuth.patch(
      `/screener-location/${locationId}/assign`,
      { userId },
    );

    return response.data;
  } catch (error) {
    console.error("Error assigning user to location:", error);
    throw error;
  }
};

///////////////////////////////////
//// Manage Screener Locations ////
///////////////////////////////////

export const getScreenerLocationsByScreenerId = async (
  screenerId: string,
): Promise<ManageLocationsResponse> => {
  try {
    const response =
      await userService.axiosWithUserAuth.get<ManageLocationsResponse>(
        `screener-location/cx/manage/${screenerId}`,
      );
    return response.data;
  } catch (error) {
    console.error("Error fetching manage screeners locations:", error);
    throw error;
  }
};

export const updateScreenerLocation = async (
  locationId: string,
  location: CleanLocationDraftForServer,
): Promise<ScreenerLocation> => {
  try {
    const response =
      await userService.axiosWithUserAuth.patch<ScreenerLocation>(
        `screener-location/cx/manage`,
        { ...location, id: locationId },
      );
    return response.data;
  } catch (error) {
    console.error("Error updating screener location:", error);
    throw error;
  }
};

export const createScreenerLocation = async (
  location: CleanLocationDraftForServer,
  screenerId: string,
) => {
  try {
    const response = await userService.axiosWithUserAuth.post<ScreenerLocation>(
      `screener-location/cx/manage`,
      { ...location, screenerId },
    );
    return response.data;
  } catch (error) {
    console.error("Error creating screener location:", error);
    throw error;
  }
};

export const bulkSetScreenerLocationStatus = async (
  locationIds: string[],
  status: IndividualScreenerLocationStatusType,
): Promise<ScreenerLocation[]> => {
  try {
    const response = await userService.axiosWithUserAuth.patch<{
      locations: ScreenerLocation[];
    }>(`screener-location/cx/manage/bulk-status`, { locationIds, status });
    return response.data.locations;
  } catch (error) {
    console.error("Error updating location statuses", error);
    throw error;
  }
};

export const deleteScreenerLocation = async (
  locationId: string,
): Promise<void> => {
  try {
    await userService.axiosWithUserAuth.delete<void>(
      `screener-location/cx/manage/${locationId}`,
    );

    return;
  } catch (error) {
    console.error("Error deleting screener location", error);
    throw error;
  }
};
