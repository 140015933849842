import type { Nullable, NullableValues, YesNo } from "@/types/utils";

// ===== Enums =====
/**
 * Steps in the screener builder workflow
 */
export enum ScreenerBuilderStep {
  Position = "position",
  Context = "context",
  Locations = "locations",
  Review = "review",
}

/**
 * Seniority levels for job positions
 */
export enum SeniorityLevel {
  EntryLevel = "Entry Level",
  JuniorLevel = "Junior Level",
  MidLevel = "Mid Level",
  SeniorLevel = "Senior Level",
  ExecutiveLevel = "Executive Level",
}

/**
 * Question types available in the screener builder
 */
export enum QuestionType {
  Identifying = "identifying",
  MultipleChoice = "multiple_choice",
  SingleChoice = "single_choice",
  ShortAnswer = "text",
  Number = "numerical",
  YesNo = "yes_no",
  Name = "name",
  Email = "email",
  Phone = "phone",
}

/**
 * Numerical rule mapping
 */
export enum NumericalRule {
  LessThan = "<",
  GreaterThan = ">",
  LessThanOrEqualTo = "<=",
  GreaterThanOrEqualTo = ">=",
  EqualTo = "=",
  NotEqualTo = "!=",
}

/**
 * Generation status of the screener builder
 */
export enum GenerationStatus {
  New = "new",
  Generating = "generating",
  Complete = "complete",
  Error = "error",
}

// ===== Base Types =====
/**
 * Base interface for condition rules used throughout the application
 */
export interface Condition {
  option_text: string;
  qualifier: string;
  logic_operator?: string;
}

// ===== Validation Rules =====
/**
 * Validation rules for internal application format
 */

export interface ValidationRules {
  yes_no_rule?: YesNo;
  choice_rules?: Condition[];
  numerical_rule?: string;
}

// ===== Screener Builder Components =====
/**
 * Position information in the screener builder
 */
export interface ScreenerBuilderPosition {
  currentScreenerQuestions: Nullable<string>;
  internalName: Nullable<string>;
  isHighTurnoverRole: Nullable<boolean>;
  jobDescription: string;
  jobTitle: string;
  seniorityLevel: Nullable<SeniorityLevel>;
}

/**
 * Context information in the screener builder
 */
export type ScreenerBuilderContext = NullableValues<{
  companyMission: string;
  companyValues: string;
  interviewGuide: string;
  otherNotes: string;
  toneOfVoiceExample: string;
}>;

/**
 * Review information in the screener builder
 */
export interface ScreenerBuilderReview {
  basic_questions: ScreenerBuilderBasicQuestion[];
  competencies: ScreenerBuilderCompetency[];
  introduction: string;
  conclusion: string;
  scoringScale: ScoringScale;
}

/**
 * Internal representation of a basic question
 */
export interface ScreenerBuilderBasicQuestion {
  identifier: string;
  question_text: string;
  question_type?: QuestionType;
  validation_rules: ValidationRules | null;
  is_qualifying: boolean;
  is_included: boolean;
  show_in_summary?: boolean;
  is_visible_on_pdf: boolean;
}

/**
 * Internal representation of competencies
 */
export interface ScreenerBuilderCompetency {
  name: string;
  display_name: string;
  definition: string;
  questions: Array<{ question: string; is_selected: boolean }>;
  scoringGuidelines: {
    high: string;
    medium: string;
    low: string;
  };
  is_included: boolean;
}

// ===== API Interfaces =====
/**
 * Complete screener builder data
 */
interface ScreenerBuilder {
  context: ScreenerBuilderContext;
  position: ScreenerBuilderPosition;
  review: ScreenerBuilderReview;
}

/**
 * API response for screener builder data
 */
export interface ScreenerBuilderResponse {
  data: ScreenerBuilder;
  generationStatus: GenerationStatus;
  published: boolean;
  screenerId: string;
  step: ScreenerBuilderStep;
}

/**
 * Map of data types by step
 */
type ScreenerBuilderDataMap = {
  [ScreenerBuilderStep.Position]: ScreenerBuilderPosition;
  [ScreenerBuilderStep.Context]: ScreenerBuilderContext;
  [ScreenerBuilderStep.Locations]: null;
  [ScreenerBuilderStep.Review]: ScreenerBuilderReview;
};

/**
 * Post body for screener builder API
 */
export interface ScreenerBuilderPostBody<T extends ScreenerBuilderStep> {
  data: ScreenerBuilderDataMap[T];
  page: T;
  progressStep?: boolean;
  screenerId?: string;
}

export interface ScreenerBuilderPublishResponse {
  failed: {
    location: string;
    error: string;
  }[];
  message: string;
  successful: {
    location: string;
    url: string;
  }[];
}

/**
 * Scoring scale options
 * 1 = Easy
 * 2 = Regular
 * 3 = Hard
 */
export enum ScoringScale {
  Easy = 1,
  Regular = 2,
  Hard = 3,
}

export enum EditorModes {
  Editor = "editor",
  Preview = "preview",
}
