import { NumericalRule } from "@/types/screener-builder";
import type { ScreenerLocation } from "@/types/screener-location";
import type { Maybe, Nullable } from "@/types/utils";

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, "");
};

export const locationHasCompletedSessions = (location?: ScreenerLocation) =>
  (location?.sessionCount ?? 0) > 0;

export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) return str;
  return str.slice(0, maxLength) + "...";
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};

export const numberIsTruthyOrZero = (
  value?: Maybe<Nullable<number>>,
): value is number => !!(!isNaN(value ?? 0) && (value || value === 0));

export const mapArrayToProperty = <
  T extends object,
  Key extends keyof T = keyof T,
>(
  arr: T[],
  property: Key,
): T[Key][] => arr.map((el) => el[property]);

// formatNumericalRule and parseNumericalRule are used to format and parse the numerical rule
const ruleMap: { [key: string]: NumericalRule } = {
  "=": NumericalRule.EqualTo,
  "<": NumericalRule.LessThan,
  ">": NumericalRule.GreaterThan,
  "!=": NumericalRule.NotEqualTo,
  ">=": NumericalRule.GreaterThanOrEqualTo,
  "<=": NumericalRule.LessThanOrEqualTo,
};

// reverseRuleMap is used to reverse the ruleMap
export const reverseRuleMap: { [key in NumericalRule]?: string } =
  Object.entries(ruleMap).reduce(
    (acc, [key, val]) => {
      acc[val] = key;
      return acc;
    },
    {} as { [key in NumericalRule]?: string },
  );

// formatNumericalRule is used to format the numerical rule
export const formatNumericalRule = (
  condition: NumericalRule,
  value: string,
) => {
  const operatorStr = reverseRuleMap[condition];
  if (operatorStr === undefined) return "";
  return `${operatorStr}${value}`;
};

// parseNumericalRule is used to parse the numerical rule
export const parseNumericalRule = (rule: string | undefined) => {
  if (!rule) return null;
  const regex = /^([<>=!]{1,2})(-?\d*\.?\d+)$/;
  const match = rule.match(regex);
  if (!match) return null;
  const operatorStr = match[1];
  const valueStr = match[2];
  const condition = ruleMap[operatorStr];
  if (condition === undefined) return null;
  return { condition, value: valueStr };
};

// OPERATOR_MAP is used to map the operator to the human readable operator string
export const OPERATOR_MAP: { [key: string]: string } = {
  "=": "equal to",
  "<": "less than",
  ">": "greater than",
  "!=": "not equal to",
  ">=": "greater than or equal to",
  "<=": "less than or equal to",
};
